import Vue from 'vue'

// 手动按需引入样式文件
// import 'vant/lib/button/style'
// 导入所有样式文件
import 'vant/lib/index.css'

// 手动按需引入组件
import {
  Cell, CellGroup,
  Field, Button, Checkbox, CheckboxGroup, Form, NumberKeyboard, CountDown,
  NavBar, Tabbar, TabbarItem, Swipe, SwipeItem,
  ActionSheet, Divider, Step, Steps, RadioGroup, Radio, Progress
} from 'vant'

// import 'vant/lib/button/style'

Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Field)
Vue.use(Button)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Form)
Vue.use(NumberKeyboard)
Vue.use(NavBar)
Vue.use(CountDown)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(ActionSheet)
Vue.use(Divider)
Vue.use(Step)
Vue.use(Steps)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Progress)
