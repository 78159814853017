import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/test' },
  // { path: '/', redirect: '/login' },
  // { path: '/login', name: 'login', component: () => import('../views/LoginView.vue') },
  // { path: '/agreement', name: 'agreement', component: () => import('../views/AgreementView.vue') },
  // { path: '/home', name: 'home', component: () => import('../views/HomeView.vue') },
  { path: '/test', name: 'test', component: () => import('../views/TestView.vue') },
  // { path: '/person', name: 'person', component: () => import('../views/PersonView.vue') },
  { path: '/MBTI', name: 'MBTI', component: () => import('../views/MBTI-TestView.vue') },
  // { path: '/MBTI-report', name: 'MBTI-report', component: () => import('../views/MBTI-ReportView.vue') }
  { path: '/SDS', name: 'SDS', component: () => import('../views/SDS-TestView.vue') }
]

const router = new VueRouter({
  routes
})

export default router
